import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		component: () => import("../views/Home.vue")
	}, /* 首页*/
	{
		path: "/companyIntroduction",
		component: () => import("../views/companyIntroduction.vue")
	}, /* 企业介绍 */
	{
		path: "/product",
		redirect: "/product/healthcare",
		component: () => import("../views/product/product.vue"),
		children: [
			{
				name: "healthcare",
				path: "healthcare",
				title: "医疗健康",
				component: () => import("../views/product/components/healthcare.vue")
			},			
			{
				name: "jointhealth",
				path: "jointhealth",
				title: "关节健康",
				component: () => import("../views/product/components/jointhealth.vue")
			}
		]
	}, /* 产品服务 */
	{
		path: "/contactUs",
		component: () => import("../views/contactUs.vue")
	}, /* 联系我们 */
	{
		path: "/news",
		component: () => import("../views/news.vue")
	}, /* 资讯*/
	{
		path: "/newDetail",
		component: () => import("../views/newDetail.vue")
	},
	{
		path: "/partner",
		component: () => import("../views/partner.vue")
	}, /* 合作客户 */
	{
		path: "/partnerDetail",
		component: () => import("../views/partnerDetail.vue")
	}
]
//解决重复点击跳转当前页面报错     /*Uncaught (in promise) NavigationDuplicated: Avoided redundant navigation to current location: "/conferenceFees".*/
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
	routes
})

export default router
